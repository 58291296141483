import ALL_WHOLESALERS from 'data/wholesalers';
import React, { useState } from 'react';
import AllWholesalersGridItem from './AllWholesalers/AllWholesalersGridItem';
import { Container, Page } from './ProductsList/WholesaleProductsList.style';
import SetupPopup from './AllWholesalers/SetupPopup';
import { Header, Wholesalers } from './Wholesale.style';
import { useQuery } from '@apollo/react-hooks';
import { GET_WHOLESALE_SHOP } from 'devoapi/shop';
import useAuth from 'contexts/auth/auth.context';

export type WholesalerMenuOption = {
  id: string;
  name: string;
  href: string;
  customerId?: string;
  signUpHref?: string;
};

type AllWholesaleProductsProps = {};

export type WholesaleProductsProps = {};

const AllWholesaleProducts: React.FC<AllWholesaleProductsProps> = ({}) => {
  const { authState } = useAuth();
  const { user } = authState;

  const { data } = useQuery(GET_WHOLESALE_SHOP, {
    skip: !user?.shopUuid,
    variables: {
      id: user?.shopUuid,
    },
  });

  const customerCode = data?.shop?.wholesalerInformation?.BOOKER?.customerCode;

  const [setupPopup, setSetupPopup] = useState<WholesalerMenuOption>(null);

  const closeModal = () => setSetupPopup(null);

  const onSetupSelect = (wholesaler: WholesalerMenuOption) => {
    setSetupPopup(wholesaler);
  };

  return (
    <Page>
      <SetupPopup
        isOpen={!!setupPopup}
        closeModal={closeModal}
        provider={setupPopup}
      />
      <Container>
        <Header>
          <h1>Wholesalers</h1>
        </Header>
        <Wholesalers.Grid>
          {ALL_WHOLESALERS.map((provider) => (
            <AllWholesalersGridItem
              {...{ provider, onSetupSelect, customerCode }}
            />
          ))}
        </Wholesalers.Grid>
      </Container>
    </Page>
  );
};

export default AllWholesaleProducts;
